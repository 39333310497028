<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/img/logo-app.png')"
              max-height="60px"
              max-width="120px"
              height="60"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
          </router-link>
        </v-card-title>
        
        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Bienvenido! 👋🏻</p>
          <p class="mb-2">Inicie sesión con su correo y contraseña</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <!-- <Alert></Alert> -->
          <v-form ref="formLogin" @submit.prevent="signIn">
            <v-text-field
              v-model="email"
              outlined
              label="Usuario"
              :rules="emailRules"
              placeholder=""
              class="mb-1"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :rules="passwordRules"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Contraseña"
              placeholder="············"
              :append-icon="
                isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
              "
              @click:append="isPasswordVisible = !isPasswordVisible"
              class="mb-0"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Recordarme" hide-details class="me-3 mt-1"> </v-checkbox>

            </div>

            <v-btn
              block
              :loading="loading"
              :disabled="loading"
              type="submit"
              color="primary"
              class="mt-6"
            >
              Iniciar sesión
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="
        require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)
      "
    />
    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      contain
      src="@/assets/images/misc/broadcast.svg"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      contain
      src="@/assets/images/misc/security.svg"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* import Alert from "@/components/Alert.vue"; */
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import { ref } from "@vue/composition-api";
import useAuth from "@/composables/useAuth";

export default {
  components: {
    /* Alert, */
  },
  setup() {
    const isPasswordVisible = ref(false);
    const emailRules = ref([
      (v) => !!v || "El usuario es requerido",
      /* (v) => /.+@.+\..+/.test(v) || "Escribe un correo válido", */
    ]);
    const passwordRules = ref([(v) => !!v || "La contraseña es requerida"])
    const { email, password, loading, formLogin, signIn } = useAuth();

    const socialLink = [
      {
        icon: mdiFacebook,
        color: "#4267b2",
        colorInDark: "#4267b2",
      },
      {
        icon: mdiTwitter,
        color: "#1da1f2",
        colorInDark: "#1da1f2",
      },
      {
        icon: mdiGithub,
        color: "#272727",
        colorInDark: "#fff",
      },
      {
        icon: mdiGoogle,
        color: "#db4437",
        colorInDark: "#db4437",
      },
    ];

    return {
      isPasswordVisible,
      emailRules,
      passwordRules,
      socialLink,
      email,
      password,
      formLogin,
      loading,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      signIn,
    };
  },
};
</script>

<style lang="scss">
@import "~@/plugins/scss/auth.scss";
</style>
